<template>
    <div class="pageBox">
        <div class="topBox">
             <div class="TB-left"> 
                <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        {{ museumName }}
                        <el-select @change="changeBuilds" v-model="build" placeholder="请选择">
                            <el-option
                            v-for="item in builds"
                            :key="item.envId"
                            :label="item.envName"
                            :value="item.envId"
                            />
                        </el-select>
                    </div>
                    <div class="floorBox">
                        <div
                        @click="clickFloor(item.id)" 
                        :class="currentFloor==item.id?'floorName currentFloor':'floorName'"
                         v-for="(item,index) in floors" 
                         :key="index">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
                <div class="titleMsgBox">
                    <mapMode ref="mapMode" @mapEvent="mapEvent"/>
                </div>
             </div>
             <div class="TB-right"> 
                <div class="titleBox">
                    <img src="../../assets/siteImg/title.png">
                    文物风险占比 | {{ CurrentRoom? CurrentRoom.name:''}}
                </div>
                <div class="titleMsgBox">
                    <div class="tips">共102件文物，合格率为70%</div>
                    <div class="chartBox">
                        <pieChart :data="senerData" :legend="true" :radius="['30%','45%']" :color="['#00B286','#FFF32F','#E48C02','#F80000']" />
                    </div>
                </div>
            </div>
        </div>
        <div class="centerBox">
            <div class="titleBox titleCss">
                <div>
                    <img src="../../assets/siteImg/title.png">
                    环境监测指标 | {{ CurrentRoom? CurrentRoom.name:''}}
                </div>
                <div>
                    <el-date-picker
                    v-model="TimeRang1"
                    @change="ChangeTimeRang1"
                    type="datetimerange"
                    range-separator="-"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    format="YYYY-MM-DD HH:mm:ss"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    />
                </div>
            </div>
            <div class="titleMsgBox CB_main">
                <div class="CB_item">
                    <lineChart :series="WSDline" :yAxis="WSDyAxis" />
                </div>
                <div class="CB_item">
                    <lineChart :series="CO2line" :yAxis="CO2yAxis" />
                </div>
            </div>
        </div>
        <div class="bottomBox">
            <div class="BT-left">
                <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        环境监测指标 | {{ CurrentRoom? CurrentRoom.name:''}}
                    </div>
                    <div class="operCss">
                        <el-radio-group v-model="senerType">
                            <el-radio-button v-for="(item,index) in senerTypeList" :key="index" :label="item.id">{{ item.name }}</el-radio-button>
                        </el-radio-group>
                        <el-date-picker
                            v-model="TimeRang2"
                            @change="ChangeTimeRang2"
                            type="daterange"
                            range-separator="-"
                            value-format="YYYY-MM-DD"
                            format="YYYY-MM-DD"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                        />
                    </div>
                </div>
                <div class="titleMsgBox">
                    <boxPlot :Time="boxTime" :data="boxData"/>
                </div>
            </div>
            <div class="BT-right">
                <div class="titleBox titleCss">
                    <div>
                        <img src="../../assets/siteImg/title.png">
                        环境监测指标超限占比 | {{ CurrentRoom? CurrentRoom.name:''}}
                    </div>
                    <div>
                        <el-select v-model="limitTime" class="selectCss" placeholder="请选择">
                            <el-option
                            v-for="item in Times"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            />
                        </el-select> 
                    </div>
                </div>
                <div class="titleMsgBox">
                    <barChart/>    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mapMode from '@/components/leaflet/index.vue'
import pieChart from '../siteHome/modules/piechart.vue'
import lineChart from '@/components/charts/lineChart.vue'
import boxPlot from '@/components/charts/boxplot.vue'
import barChart from '@/components/charts/barChart.vue'
import { site_envList, site_envMap, site_envData, site_listDeviceData1, site_countExhibitionid } from '@/api/site-home.js'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default{
    components:{
        mapMode,
        pieChart,
        lineChart,
        boxPlot,
        barChart
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            museumName:'',
            builds:[],
            build:null,
            floors:[],
            currentFloor:null,
            CurrentRoom:null,
            senerData:[
                { value: 0, name: '优' },
                { value: 0, name: '良' },
                { value: 0, name: '中' },
                { value: 0, name: '差' },
            ],
            TimeRang1:['2023-08-01 11:00:00', '2023-08-18 11:00:00'],
            WSDline:[
                { name: '温度（℃）', type: 'line', smooth: true, symbol: 'none', areaStyle: {}, data:[] },
                { name: '湿度（%RH）', yAxisIndex: 1, type: 'line', smooth: true, symbol: 'none',  areaStyle: {}, data: []}
            ],
            WSDyAxis:[
                { name: '温度(℃)', type: 'value' },
                { name: '湿度(%RH)', alignTicks: true, type: 'value' }
            ],
            CO2line:[
                {name: '二氧化碳（ppm）', type: 'line', smooth: true, symbol: 'none', areaStyle: {}, data:[]}
            ],
            CO2yAxis:[{name: '二氧化碳(ppm)',type: 'value'}],
            TimeRang2:['2023-08-01','2023-08-18'],
            senerType:'01',
            senerTypeList:[
                {name:'湿度', id:'01',unit:'%RH'},
                {name:'温度', id:'02',unit:'℃'},
                {name:'二氧化碳', id:'03',unit:'ppm'},
                {name:'光照', id:'04',unit:'lx'},
                {name:'紫外', id:'05',unit:'μw/cm2'}
            ],
            boxData:[],
            boxTime:[],
            typeTime:1,
            Times:[
                {label:'近24小时', value:1},
                {label:'近一周', value:2}
            ],
            limitTime:1
        }
    },
    mounted(){
        this.getMap()
    },
    methods:{
        moment,
        changeBuilds(id){
            this.envId = id
            this.getFloor()
        },
        getMap(){
            let minQuery = {
                'siteId':this.siteId,
                'envid':this.siteId
            }
            site_envMap(minQuery).then(res=>{
                this.builds = res.data.envs
                this.build =  this.$route.query.envId
                this.getFloor()
            })
            let minQuery2 = {
            'siteId':this.siteId,
            'envId':this.siteId,
            'isSelf':true,
            'isTree':true
            }
            site_envList(minQuery2).then(res=>{
                this.museumName = res.data[0].envName
            })
        },
        getFloor(){
            let minQuery = {
                'siteId':this.siteId,
                'envId':this.build,
                'isSelf':false,
                'isTree':true,
            }
            site_envList(minQuery).then(res=>{
                this.floors = res.data.map(i=>{
                    let obj = {
                        label:i.envName,
                        id:i.envId
                    }
                    return obj
                })
                if(this.floors.length>0){
                    this.currentFloor = this.floors[0].id
                    this.clickFloor(this.currentFloor)
                }
                // this.getRoom()
            })
        },
        getRoom(){
                let minQuery = {
                    'siteId':this.siteId,
                    'envid':this.currentFloor
                }
                site_envMap(minQuery).then(res=>{
                    let data = res.data.envs
                    let src = `${window.g.imgPath}${res.data.layoutUrl}`
                    let line = []
                    data.forEach(el => {
                        let obj = {
                            type:'polygon',
                            points:JSON.parse(el.latlngs),
                            name:el.envName,
                            envId:el.envId 
                        }
                        line.push(obj)
                    });
                    this.$refs.mapMode.init(src, line)
                })
        },
        clickFloor(id){
            this.currentFloor = id
            this.getRoom()
        },
        ChangeTimeRang1(time){
            this.TimeRang1 = time
            if(this.CurrentRoom&&this.CurrentRoom.envId){
                this.Get_envData(1,'01',this.TimeRang1[0],this.TimeRang1[1]) //湿度
                this.Get_envData(2,'02',this.TimeRang1[0],this.TimeRang1[1]) //温度
                this.Get_envData(3,'03',this.TimeRang1[0],this.TimeRang1[1]) //二氧化碳
            }
        },
        ChangeTimeRang2(time){
            this.TimeRang2 = time
            if(this.CurrentRoom&&this.CurrentRoom.envId){
                this.Get_listDeviceData1(this.TimeRang2[0],this.TimeRang2[1]) //环境监测指标
            }
        },
        Get_envData(type,senerType,startDateTime, endDateTime){
            let Query = {
                siteId:this.siteId,
                envId:this.CurrentRoom.envId,
                envirParamType:senerType,
                startDateTime:startDateTime,
                endDateTime:endDateTime,
            }
            site_envData(Query).then(r=>{
                let data = r.data.data.map(i=>{
                    return [moment(i.collectTime).valueOf(), i.envirParamValue]
                })
                switch (type) {
                    case 1:
                        this.WSDline[1].data = data
                        break;
                    case 2:
                        this.WSDline[0].data = data
                        break;
                    case 3:
                        this.CO2line[0].data = data
                        break;
                    default:
                    //默认代码块
                }
            })
        },
        Get_listDeviceData1(start, end){
            let Query = {
                start:start,
                end:end,
                envid:this.CurrentRoom.envId,
                deviceType:this.senerType
            }
            site_listDeviceData1(Query).then(r=>{
                this.boxTime = r.data.map(i=>{
                    return moment(i.daytime)
                })
                this.boxData = r.data.map(i=>{
                    return [i.max, i.avg,  i.min]
                }) 
            })
        },
        Get_site_countExhibitionid(){
            let query = {
                envid:this.CurrentRoom.envId
            }
            site_countExhibitionid(query).then(r=>{
                this.senerData[0].value = r.data.excellent
                this.senerData[1].value = r.data.good
                this.senerData[2].value = r.data.average
                this.senerData[3].value = r.data.poor
            })
        },   
        makeData(){
            let base = +new Date(2023, 7, 1);
            let oneDay = 24 * 3600 * 1000;
            let data = [[base, Math.random() * 300]];
            for (let i = 1; i < 100; i++) {
                let now = new Date((base += oneDay));
                data.push([+now, Math.round((Math.random() - 0.5) * 20 + data[i - 1][1])]);
            }
            return data
        }, 
        mapEvent(e){
            if(e.type == 'dblclick'){
                this.$router.push({
                    path:'/inside/siteRoom',
                    query:{
                        floorsId:this.currentFloor,
                        envId:e.target.options.userData.envId
                    }
                })
            }else{
                this.CurrentRoom = e.target.options.userData
                if(this.TimeRang1){
                    this.Get_envData(1,'01',this.TimeRang1[0],this.TimeRang1[1]) //湿度
                    this.Get_envData(2,'02',this.TimeRang1[0],this.TimeRang1[1]) //温度
                    this.Get_envData(3,'03',this.TimeRang1[0],this.TimeRang1[1]) //二氧化碳
                }
                if(this.TimeRang2){
                    this.Get_listDeviceData1(this.TimeRang2[0],this.TimeRang2[1]) //环境监测指标
                }
                this.Get_site_countExhibitionid()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}
.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.topBox{
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: space-between;
  
  .TB-left{
    width: 75%;
    height: 100%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .floorBox{
        display: flex;
        justify-content: center;
        align-items: center;
        .floorName{
            margin: 0px 30px;
            cursor: pointer;
        }
        .currentFloor{
            color: #22A6F5;
        }
    }
    
  }
  .TB-right{
    width: calc(25% - 20px);
    height: 100%;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .tips{
        height: 50px;
        line-height: 50px;
        text-align: right;
        padding: 0px 20px;
        box-sizing: border-box;
        font-size: 14px;
    }
    .chartBox{
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 10px
    }
  }
}

.centerBox{
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-top: 20px;
    .CB_main{
        display: flex;
        justify-content: space-between;
        .CB_item{
           width: calc(50% - 20px);
           height: 100%; 
        }
    }
}

.bottomBox{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .BT-left{
        width: 60%;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
        .operCss{
            display: flex;
            justify-content: center;
            align-items: center;
            .selectCss{
                margin-left: 20px;
            }
        }
    }
    .BT-right{
        width:calc(40% - 20px);
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
    }
}
</style>